import React, { useState, useEffect } from "react";
import DashFooter from "../DashFooter/DashFooter";
import DashHeader from "../DashHeader/DashHeader";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { API } from "../../Utils/API";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateAuth } from "../../Redux/AuthSlice";
import moment from "moment";
import DOMPurify from "dompurify";

const Gross_Binary_Earning_Report = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(50);
  const [totalCount, settotalCount] = useState(50);
  const [todate, setTodate] = useState("");
  const [fromdate, setFromdate] = useState("");
  const [totalamount, settotalamount] = useState(0);
  const { userId, jwtToken } = useSelector((state) => state.Auth);

  const Direct_API = async () => {
    try {
      let responce = await API?.post(
        `/GrossRoiIncome`,
        {
          uid: userId,
          fdate: fromdate,
          tdate: todate,
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json", // Adjust the content type as needed
          },
        }
      );
      console.log("activationHistory", responce);
      let responce1 = responce.data.totalamount;
      console.log("data", responce1);
      responce = responce?.data?.data[0];
      settotalamount(responce1);
      let arr = [];
      let i = 1;
      responce.forEach((item, index) => {
        arr.push({
          Number: i,
          User_Id: item?.uid,
          // wallet: `${item.wallet.substring(0, 6)}...${item.wallet.substring(
          //   item.wallet.length - 4
          // )}`,

          Date: moment(item.date).format("DD/MM/YYYY"),
          BinaryIncome: `$ ${item?.BinaryIncome}`,
          CapingAmount: `$ ${item?.CapingAmount}`,
          MatchingAmount: `$ ${item?.MatchingAmount}`,
          MatchingBv: `$ ${item?.MatchingBv}`,
        });
        i++;
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  useEffect(() => {
    Direct_API();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "Number" },
      { Header: "User ID", accessor: "User_Id" },
      // { Header: "Wallet", accessor: "wallet" },
      { Header: "Matching Bv", accessor: "MatchingBv" },
      { Header: "Income", accessor: "BinaryIncome" },
      { Header: "Caping Amount", accessor: "CapingAmount" },
      // { Header: "Matching Amount", accessor: "MatchingAmount" },

      { Header: " Date", accessor: "Date" },
    ],
  });
  return (
    <div>
      <DashHeader />
      <div className="page-wrapper">
        <div className="page-content adjustmentsection">
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold">
                  {" "}
                  Gross Matching Income ( Total : {totalamount} )
                </div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">Reports</div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="javascript:;">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page">
                          Matching Income
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-0">
            <div className="row">
              <div className="row mt-3 mb-3">
                <div className="col-md-2 text-white">
                  <label> From Date</label>
                  <input
                    type="date"
                    name="from_date"
                    onChange={(e) =>
                      setFromdate(DOMPurify.sanitize(e.target.value))
                    }
                    id="from_date"
                    className="form-control"
                    placeholder="dd-mm-yyyy"
                  />
                </div>
                <div className="col-md-2 text-white">
                  <label > To Date</label>
                  <input
                    type="date"
                    placeholder="dd-mm-yyyy"
                    onChange={(e) =>
                      setTodate(DOMPurify.sanitize(e.target.value))
                    }
                    name="to_date"
                    id="to_date"
                    className="form-control"
                  />
                </div>

                <div className="col-md-2 mt-4">
                  <input
                    type="submit"
                    name="to_date"
                    value="Search"
                    onClick={() => Direct_API()}
                    className="btn btn-Green"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 jashja">
              <Table data={[...referralApi]} columns={matching_income.cols} />
              <Table_Buttons
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPost={indexOfLastPost}
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={totalCount}
                listPerpage={listPerpage}
                handleRowsPerPageChange={setlistPerpage}
              />
            </div>
          </div>
        </div>
      </div>
      <DashFooter />
    </div>
  );
};

export default Gross_Binary_Earning_Report;
