import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import Roadmap from "./Roadmap";
import Popup from "./Popup";

const Hero = () => {
  return (

    <div>
      {/* <Popup /> */}
      <Header />
      <div className="container-fluid  homepage ">
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-md-6 d-flex align-items-start justify-content-center flex-column order-2 order-md-1">
              <h1 className="heading">
                DECENTRALIZED CRYPTOCURRENCY IS PRODUCED.
              </h1>
              <p className="perograph my-3">
                Cryptocurrency has revolutionized the financial landscape, offering decentralized and secure transactions. As the crypto industry evolves, new technologies are emerging to enhance its capabilities.
              </p>
              <div className=" perograph2 my-3">
                <strong>Contract Addrss</strong>: 0xED9B3D9A4B8f1a567761c793bb88214904832d17</div>
              <button className="btn bg-white text-black fw-bold w-auto addbtn">Copy Address</button>
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <div className="heoimage">
                <center>
                <img
                  src="./assets/image/home/nft-gif.png"
                  className=""
                  alt="gif"
                />
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>

      <>
        <div className="container-fluid py-lg-5 py-3 aboutsection" id="about">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 order-md-1 order-2">
                <div className="sectionheading">About Meta Rags & its Future</div>
                <p className="my-4">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
              </div>
              <div className="col-md-6 order-md-2 order-1">
                <div className="image">
                  <img className="w-100 h-100" src="./assets/image/home/rocket.png" alt="rocket" />
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-6 ">
                <div className="image">
                  <img className="w-100 h-100" src="./assets/image/home/Cryptocurrency.png" alt="rocket" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="sectionheading">Cryptocurrency has revolutionized the financial landscape</div>
                <p className="my-4">Cryptocurrency has revolutionized the financial landscape, offering decentralized and secure transactions. As the crypto industry evolves, new technologies are emerging to enhance its capabilities. One such technology is hybrid intelligence, which combines the power of artificial intelligence (AI) with Blockchain technology. In this article, we will explore the concept of hybrid crypto and its potential to shape the future of the cryptocurrency market.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid py-lg-5 py-4 Benefits " id="Benifits">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center  mb-5">
                <div className="sectionheading mb-3">Meta Ragscoin 2.0 Benefits</div>
                <p className="subheading ">Hybrid crypto offers several key benefits that can revolutionize the cryptocurrency market</p>
              </div>
            </div>
            <div className="row justify-content-md-center g-4">
              <div className="col-lg-4 col-md-6">
                <div className="card benfitscard">
                  <div className="card-body">
                    <div className="card-image">
                      <img className="w-100 h-100" src="./assets/image/home/Security.svg" alt="icon" />
                    </div>
                    <div className="heading">Security</div>
                    <p>Ensures a higher level of security for user</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="card benfitscard">
                  <div className="card-body">
                    <div className="card-image">
                      <img className="w-100 h-100" src="./assets/image/home/Efficiency.svg" alt="icon" />
                    </div>
                    <div className="heading">Efficiency</div>
                    <p>Streamlines processes, increases overall efficiency.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="card benfitscard">
                  <div className="card-body">
                    <div className="card-image">
                      <img className="w-100 h-100" src="./assets/image/home/Decision-making.svg" alt="icon" />
                    </div>
                    <div className="heading">Decision-making</div>
                    <p>Decisions based on real-time market conditions.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="card benfitscard">
                  <div className="card-body">
                    <div className="card-image">
                      <img className="w-100 h-100" src="./assets/image/home/Predictive Insights.svg" alt="icon" />
                    </div>
                    <div className="heading">Predictive Insights</div>
                    <p>Insights into market trends and investment opportunities.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="card benfitscard">
                  <div className="card-body">
                    <div className="card-image">
                      <img className="w-100 h-100" src="./assets/image/home/User-Friendly Interfaces.svg" alt="icon" />
                    </div>
                    <div className="heading">User-Friendly Interfaces</div>
                    <p>Makes it easier for both novice and experienced users.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid py-lg-5 py-3 white-papersection">
          <div className="container">
            <div className="row align-items-center mb-5">
              <div className="col-md-6">
                <div className="sectionheading mb-3">Meta Rags WHITEPAPER</div>
                <p className="mb-3">We are in the process of partnering with corporations to allow merchants to pay Meta Rags across the world using several platforms, including hotels, ed tech, and ecommerce.</p>
                <a className="btn bg-white rounded-3 fw-bold w-auto py-3 whitepaper" target="_blank" href="whitepapers.pdf">WHITE PAPER</a>
              </div>
              <div className="col-md-6">
                <div className="imagelogos p-5">
                  <img className="w-100 h-100" src="./assets/image/home/logos.png" alt="image" />
                </div>
              </div>
            </div>
            <div className="row align-items-center getsection">
              <div className="col-md-6">
                <div className="headingget">Get involved</div>
                <ul className="socialicons mt-5">
                  <li><a target="_blank" href="https://x.com/Meta Ragscoin"><span><img src="./assets/image/home/twitter.png" className="w-100 h-100" alt="Twitter"/></span> Twitter</a></li>
                  <li><a target="_blank" href="https://m.facebook.com/61560572002481/"><span><img src="./assets/image/home/facebook.png" className="w-100 h-100" alt="Medium"/></span> Facebook</a></li>
                  <li><a target="_blank" href="https://t.me/Meta Ragscoin2"><span><img src="./assets/image/home/telegram.png" className="w-100 h-100" alt="Telegram"/></span> Telegram</a></li>
                  <li><a target="_blank" href="https://www.instagram.com/Meta Rags2.0coin/"><span><img src="./assets/image/home/instagram.png" className="w-100 h-100" alt="Discord"/></span> Instagram</a></li>
                  <li><a target="_blank" href="https://discord.com/channels/@me"><span><img src="./assets/image/home/discord.png" className="w-100 h-100" alt="Discord"/></span> Discord</a></li>
                  <li><a target="_blank" href="https://youtube.com/@Meta Rags2.0?feature=shared"><span><img src="./assets/image/home/youtube.png" className="w-100 h-100" alt="Discord"/></span> Youtube</a></li>
                  <li><a target="_blank" href="https://linktr.ee/Meta Rags2.0"><span><img src="./assets/image/home/link.png" className="w-100 h-100" alt="Discord"/></span> Linktr</a></li>

                </ul>                  

              </div>
              <div className="col-md-6">
                <div className="card getcard p-sm-5 p-2">
                  <div className="card-body">
                      <div className="card-heading mb-4">start trading on Meta Rags</div>
                      <button className="btn getbtn fw-bold py-3 px-3">GET STARTED</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid py-lg-5 py-3 servicesection" id="Service">
          <div className="container">
            <div className="row mb-lg-5 mb-4">
              <div className="col-12 text-center">
                <div className="headingservice mb-3">Services</div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="card servicecard">
                  <div className="card-body">
                    <div className="card-image">
                      <img className="w-100 h-100" src="./assets/image/home/safeservice.png" alt="image" />
                    </div>
                    <div className="serviceheadingcard">Safe Service</div>
                    <p>Safe Service in cryptocurrency ensures secure transactions, reliable storage, user privacy, robust encryption, and protection against fraud and hacking, prioritizing overall security and trustworthiness.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card servicecard">
                  <div className="card-body">
                    <div className="card-image">
                      <img className="w-100 h-100" src="./assets/image/home/Fast Transfer.png" alt="image" />
                    </div>
                    <div className="serviceheadingcard">Fast Transfer</div>
                    <p>Fast Transfer enables swift movement of funds or assets between accounts or users, leveraging advanced technology to ensure quick, seamless transactions with minimal processing time.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card servicecard">
                  <div className="card-body">
                    <div className="card-image">
                      <img className="w-100 h-100" src="./assets/image/home/Secure Transfer.png" alt="image" />
                    </div>
                    <div className="serviceheadingcard">Secure Transfer</div>
                    <p>Secure Transfer ensures encrypted, tamper-proof transmission of data or assets between parties, safeguarding against unauthorized access or interception, prioritizing confidentiality, integrity, and authentication.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

      <Footer />
    </div>
  );
};

export default Hero;
