import { useState } from "react";
import DashFooter from "../DashFooter/DashFooter";
import DashHeader from "../DashHeader/DashHeader";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { useEffect } from "react";
import { updateAuth } from "../../Redux/AuthSlice";
import { API } from "../../Utils/API";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function GameWalletTransfer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(50);
  const [totalCount, settotalCount] = useState(50);
 
  const { userId, jwtToken } = useSelector((state) => state.Auth);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  const [dash, setdataArray] = useState([]);
  const Dashboard_API = async () => {
    try {
      let responce = await API?.get(`userDetails?uid=${userId}`, {
        headers: {
          Authorization: `${jwtToken}`,
          "Content-Type": "application/json",
        },
      });
      //   console.log("responce--->", responce);
      responce = responce.data.data[0][0];
      setdataArray(responce);
    } catch (e) {
      console.log("Error While calling dashboard API", e);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  const GameTransferButton_API = async () => {
    try {
      let responce = await API?.post(
        `/TransferNetWalletToGameWallet`,
        {
          uid: userId,
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );
    //   console.log("Gamebutton", responce.data?.data?.result);
      //   responce = responce?.data?.data;
      if (responce.data?.data?.result == "Success") {
        toast.success("Successfully Transfer");
        Dashboard_API();
        GameTransferHistory_API();
      } else {
        toast.error(responce.data?.data?.result);
      }
    } catch (e) {
      console.log("Error While calling tranbutton API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  const GameTransferHistory_API = async () => {
    try {
      let responce = await API?.post(
        `/GameWalletHistory`,
        {
          uid: userId,
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      //   console.log("GameThis", responce);
      responce = responce?.data?.data;
      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: item?.row,
          Registration_Date: item?.registrationDate,
          User_ID: item?.uid,
          Wallet: `${item?.wallet?.substring(0, 6)}...${item?.wallet?.substring(
            item?.wallet?.length - 4
          )}`,
          Amount: `$ ${item?.Amount}`,
          tokenamount: `${item?.tokenamount} Rags`,
          date: item?.dd,

          remark: item?.remark,
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  useEffect(() => {
    Dashboard_API();
    GameTransferHistory_API();
  }, []);

  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "User ID", accessor: "User_ID" },
      { Header: "Wallet", accessor: "Wallet" },
      { Header: "Amount", accessor: "Amount" },
      { Header: "Token Amount", accessor: "tokenamount" },
      { Header: "Transfer Date", accessor: "date" },
      { Header: "Remark", accessor: "remark" },
    ],
  });

  return (
    <div>
      <DashHeader />
      <div className="page-wrapper">
        <div className="page-content adjustmentsection">
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold">
                  Game Wallet Transfer
                </div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">
                    Game Wallet Transfer
                  </div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="javascript:;">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page">
                          Game Wallet Transfer
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-0 mb-3">
            {/* <div className="col-md-3">
              <p style={{ color: "white" }}>Click On Button To Transfer</p>
            </div> */}
            {/* 
            <br />
            <br /> */}
            <div className="col-md-auto mt-1">
              <p style={{ color: "white" }}>
                Click On Button To Transfer{" "}
                <span style={{ color: "red" }}>{dash.NetGameAmount} Rags</span>{" "}
                To Rags Pride Game Wallet
              </p>
              <input
                type="button"
                name="to_date"
                className="btn btn-primary mt_5 bgmain border-0 px-3 shadow-none w-auto"
                defaultValue="Transfer To Game Wallet"
                onClick={() => GameTransferButton_API()}
              />
            </div>
          </div>
          <div className="row mx-0">
            <div className="col-12 jashja">
              <Table data={[...referralApi]} columns={matching_income.cols} />
              <Table_Buttons
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPost={indexOfLastPost}
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={totalCount}
                listPerpage={listPerpage}
                handleRowsPerPageChange={setlistPerpage}
              />
            </div>
          </div>
        </div>
      </div>
      <DashFooter />
    </div>
  );
}
