import React, { useState, useEffect } from "react";
import DashFooter from "../DashFooter/DashFooter";
import DashHeader from "../DashHeader/DashHeader";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { API } from "../../Utils/API";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateAuth } from "../../Redux/AuthSlice";
import { toast } from "react-toastify";

const Helpdesk = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(50);
  const [totalCount, settotalCount] = useState(50);
  const { userId, jwtToken } = useSelector((state) => state.Auth);
  const [message, setmessage] = useState("");

  const Helpdesk_Post_API = async () => {
    try {
      if (message != "") {
        let responce = await API?.post(
          `/Helpdesk_By_User`,
          {
            uid: userId,
            query: message,
          },
          {
            headers: {
              Authorization: `${jwtToken}`,
              "Content-Type": "application/json", // Adjust the content type as needed
            },
          }
        );
        console.log("Helpdesk_Post_API", responce);
        responce = responce?.data?.data;
        toast.success(responce);

        setmessage("");
        Helpdesk_API();
      } else {
        toast.error("Please Enter your query...");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const Helpdesk_API = async () => {
    try {
      let responce = await API?.post(`/UserHelpDeskHistory`,{uid: userId,},
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json", // Adjust the content type as needed
          },
        }
      );
      console.log("Helpdesk_API", responce);
      responce = responce?.data?.data;
      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          Number: item?.row,
          User_Id: item?.user_id,
          Date: item?.edate[0],
          query: item?.query,
          reply: item?.reply_message,
          status: item?.status,
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  useEffect(() => {
    Helpdesk_API();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "Number" },
      { Header: "User ID", accessor: "User_Id" },
      { Header: "Query", accessor: "query" },
      { Header: "Date", accessor: "Date" },
      { Header: "Status", accessor: "status" },
      { Header: "Admin Reply", accessor: "reply" },
    ],
  });
  return (
    <div>
      <DashHeader />
      <div className="page-wrapper">
        <div className="page-content adjustmentsection">
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold"> Helpdesk</div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">Helpdesk</div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="javascript:;">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Helpdesk
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-0">
            <div className="col-12 jashja">
              <div>
                <label className="fs-18 text-white fw-bold">Query :</label>
                <textarea
                  id="message"
                  name="message"
                  value={message}
                  maxLength={100}
                  onChange={(e) => setmessage(e.target.value)}
                />
              </div>
              <button onClick={() => Helpdesk_Post_API()}> Submit</button>
            </div>
            <div className="col-12 jashja">
              <Table data={[...referralApi]} columns={matching_income.cols} />
              <Table_Buttons
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPost={indexOfLastPost}
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={totalCount}
                listPerpage={listPerpage}
                handleRowsPerPageChange={setlistPerpage}
              />
            </div>
          </div>
        </div>
      </div>
      <DashFooter />
    </div>
  );
};

export default Helpdesk;
