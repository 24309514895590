import React from 'react'

function Roadmap() {
  return (
<section class="timeline" id="roadmap">
				<h1 class="roadmap-heading" data-aos="zoom-out" data-aos-delay="400">Road<span>map</span></h1>
				<div class="swiper mySwiper2">
					<div class="swiper-wrapper">
						<div class="swiper-slide">
							<li>
								<div class="fill-yellow">
									<time>September 2023</time> <strong>Airdrop</strong>  <br/>
									<span>You all are thoroughly invited to the Airdrop of our Eagle Battle till <strong>5 Oct.</strong></span>
								</div>
							</li>
						</div>
						<div class="swiper-slide">
							<li>
								<div>
									<time>October 2023</time><b>1st NFT’s Batch Launching</b>
									<span>You all are thoroughly invited to the launch of our first 3 Patti NFTs.</span>
								</div>
							</li>
						</div>
						<div class="swiper-slide">
							<li>
								<div>
									<time>November 2023</time><b>1st Game Launching</b>
									<span>Have you purchased the NFTs already? Get ready to earn as <strong style={{color:"#fcf625"}}>3 Patti</strong> is launching.</span>
								</div>
							</li>
						</div>
						<div class="swiper-slide">
							<li>
								<div>
									<time>December 2023</time><b>2nd NFT’s Batch Launching</b>
									<span>The second batch of NFTs is all set for a zesty launch, be ready!</span>
								</div>
							</li>
						</div>
						<div class="swiper-slide">
							<li>
								<div>
									<time>January 2024</time><b> 2nd Game Launching</b>
									<span>Can you guess what the second game is? Tell us, we are waiting. It’s none
										other than <strong style={{color:"#fcf625"}}>Deal Rummy</strong>.</span>
								</div>
							</li>
						</div>
						<div class="swiper-slide">
							<li>
								<div>
									<time>February 2024</time><b>3rd NFT’s Batch Launching</b>
									<span>The Third batch of our card NFTs is all set to go live by now.</span>
								</div>
							</li>
						</div>
						<div class="swiper-slide">
							<li>
								<div>
									<time>February, 2024</time><b>3rd Game Launching</b>
									<span><strong style={{color:"#fcf625"}}>‘Point Rummy’</strong> you must have seen this name somewhere. Well, forget the past, and
										let’s focus on the present.</span>
								</div>
							</li>
						</div>
						<div class="swiper-slide">
							<li>
								<div>
									<time>March 2024</time><b>4th NFT’s Batch Launching</b>
									<span>Let’s just wait. Our fourth batch of NFTs is about to
										launch.</span>
								</div>
							</li>
						</div>
						<div class="swiper-slide">
							<li>
								<div>
									<time>April 2024</time><b> 4th Game Launching</b>
									<span>Guess the fourth game and we have a surprise for you.</span>
								</div>
							</li>
						</div>
						<div class="swiper-slide">
							<li>
								<div>
									<time>April 2024</time><b> 5th NFT’s Batch Launching</b>
									<span>The fifth batch is now all set for coming into your collection, purchase
										now.</span>
								</div>
							</li>
						</div>
						<div class="swiper-slide">
							<li>
								<div>
									<time>May 2024</time><b>5th Game Launching</b>
									<span>Hi-Five is all we can say because the name of this game is enough to get you
										excited.</span>
								</div>
							</li>
						</div>
						<div class="swiper-slide">
							<li>
								<div>
									<time>June 2024</time><b>6th NFT’s Batch Launching</b>
									<span>This time, the sixth batch will contain something different from the
										rest.</span>
								</div>
							</li>
						</div>
						<div class="swiper-slide">
							<li>
								<div>
									<time>July 2024</time><b>6th Game Launching</b>
									<span>Just like this batch of NFTs, we will take a diversion.</span>
								</div>
							</li>
						</div>

						<div class="swiper-slide">
							<li>
								<div>
									<time>August 2024</time><b>7th NFT’s Batch Launching</b>
									<span>Often considered a mystique in some cultures, the seventh batch will include
										magical NFTs.</span>
								</div>
							</li>
						</div>
						<div class="swiper-slide">
							<li>
								<div>
									<time>September 2024</time><b>7th Game Launching</b>
									<span>Talking about the game, we should probably not do that. </span>
								</div>
							</li>
						</div>

						<div class="swiper-slide">
							<li>
								<div>
									<time>October 2024</time><b>8th NFT’s Batch Launching</b>
									<span>This will be the last batch of NFTs in the roadmap, but it’s definitely not a
										full stop.</span>
								</div>
							</li>
						</div>
						<div class="swiper-slide">
							<li>
								<div>
									<time>November 2024</time><b>8th Game Launching</b>
									<span>This batch of NFTs will be for our Indian Card game lovers.</span>
								</div>
							</li>
						</div>
						<div class="swiper-slide">
							<li>
								<div>
									<time>December 2024</time><b>9th NFT’s Batch Launching</b>
									<span>Have you ever heard of risking all for it? Well, this batch of NFTs will be
										worth it.</span>
								</div>
							</li>
						</div>
						<div class="swiper-slide">
							<li>
								<div>
									<time>January 2025</time><b>9th Game Launching</b>
									<span>Not bringing the Jack-of-all-trades in between. </span>
								</div>
							</li>
						</div>
						<div class="swiper-slide">
							<li>
								<div>
									<time>February 2025</time><b>10th NFT’s Batch Launching</b>
									<span>Also know as the number of completeness. This Batch will complete your
										desires.</span>
								</div>
							</li>
						</div>
						<div class="swiper-slide">
							<li>
								<div>
									<time>March 2025</time><b>10th Game Launching</b>
									<span>Break the monotony, because it’s time to call.</span>
								</div>
							</li>
						</div>
						<div class="swiper-slide">
							<li>
								<div>
									<time>April 2025</time><b>11th NFT’s Batch Launching</b>
									<span>One of the most intriguing batches of NFTs by Metarags.community. Hands Down.</span>
								</div>
							</li>
						</div>
						<div class="swiper-slide">
							<li>
								<div>
									<time>May 2025</time><b>11th Game Launching</b>
									<span>Are you feeling a bit creative? Don’t worry, as we have got Call Break for
										you.</span>
								</div>
							</li>
						</div>


					</div>
				</div>
			</section>
  )
}

export default Roadmap